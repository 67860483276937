<template>
  <div>
    package Report
  </div>
</template>
<script>
import { core } from '../../../config/pluginInit'

export default {
  name: 'packageReports',
  mounted () {
    core.index()
  }
}
</script>
<style>

</style>
